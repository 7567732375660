import { Redirect, Route } from "react-router-dom";

import {
    hydraDataProvider as baseHydraDataProvider,
    fetchHydra as baseFetchHydra,
    useIntrospection,
} from "@api-platform/admin";

import { ENTRYPOINT } from "../config/entrypoint";

import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";

const getHeaders = () =>
    localStorage.getItem("token")
        ? {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
          }
        : {};
const fetchHydra = (url, options = {}) =>
    baseFetchHydra(url, {
        ...options,
        headers: getHeaders,
    });

const RedirectToLogin = () => {
    const introspect = useIntrospection();

    if (localStorage.getItem("token")) {
        introspect();
        return <></>;
    }
    return <Redirect to="/login" />;
};

const apiDocumentationParser = async () => {
    try {
        const { api } = await parseHydraDocumentation(ENTRYPOINT, {
            headers: getHeaders,
        });
        return { api };
    } catch (result) {
        if (result.status !== 401) {
            throw result;
        }

        // Prevent infinite loop if the token is expired
        localStorage.removeItem("token");

        return {
            api: result.api,
            customRoutes: [
                <Route key="/" path="/" component={RedirectToLogin} />,
            ],
        };
    }
};

const hydraDataProvider = baseHydraDataProvider({
    entrypoint: ENTRYPOINT,
    httpClient: fetchHydra,
    apiDocumentationParser,
});

const extendedDataProvider = {
    ...hydraDataProvider,
    verify: (resource, params) => {
        return Promise.resolve(
            fetchHydra(
                ENTRYPOINT +
                    "/admin/ceres_users/" +
                    params.id +
                    "/verify_profession",
                {
                    method: "PUT",
                    body: JSON.stringify([]),
                }
            )
        ).then(({ json }) => ({
            data: json,
        }));
    },
    download: (resource, params) => {
        return Promise.resolve(
            fetch(ENTRYPOINT + params.path, {
                method: "GET",
                headers: getHeaders(),
            })
        ).then(async function (result) {
            const blob = await result.blob();
            const href = window.URL.createObjectURL(blob);

            return {
                data: href,
            };
        });
    },
};

export default extendedDataProvider;
