import { HydraAdmin, ResourceGuesser } from "@api-platform/admin";

import {
    Labeled,
    List,
    Datagrid,
    SelectInput,
    TextField,
    BooleanField,
    ShowButton,
    TextInput,
    useMutation,
    Button,
    Show,
    TabbedShowLayout,
    Tab,
    useNotify,
    useRefresh,
    Edit,
    SimpleForm,
    ReferenceField,
    SaveButton,
    Toolbar,
    useRedirect,
    defaultTheme,
    Layout,
    TopToolbar,
    DeleteButton,
} from "react-admin";

import ContentCreate from "@material-ui/icons/Create";
import OpenInNew from "@material-ui/icons/OpenInNew";

import { useRef } from "react";

import UserIcon from "@material-ui/icons/People";
import authProvider from "./utils/authProvider";
import dataProvider from "./utils/dataProvider";
import { ENTRYPOINT } from "./config/entrypoint";

import {
    InviteRequestList,
    InviteRequestCreate,
} from "./InviteRequest/InviteRequest";

const ceresUserFilters = [
    <TextInput source="q" label="Suche" alwaysOn allowEmpty />,
    <SelectInput
        alwaysOn
        source="professionVerificationState"
        label="Berufsstatus"
        choices={[
            { id: "missing", name: "Missing" },
            { id: "pending", name: "Pending" },
            { id: "verified", name: "Verified" },
        ]}
    />,
    <SelectInput
        alwaysOn
        source="country"
        label="Land"
        choices={[
            { id: "germany", name: "Deutschland" },
            { id: "switzerland", name: "Schweiz" },
            { id: "liechtenstein", name: "Liechtenstein" },
            { id: "others", name: "Andere" },
        ]}
    />,
    <SelectInput
        alwaysOn
        source="profession"
        label="Beruf"
        choices={[
            { id: "anaesthesiepfleger", name: "Anästhesieschwester/pfleger" },
            { id: "andereFachperson", name: "Andere Fachperson" },
            { id: "apothekenhelfer", name: "Apothekenhelfer*in" },
            { id: "apotheker", name: "Apotheker*in" },
            { id: "arzt", name: "Arzt/Ärztin" },
            { id: "arzthelfer", name: "Arzthelfer*in (MFA)" },
            { id: "chiropraktiker", name: "Chiropraktiker*in" },
            { id: "drogist", name: "drogist" },
            { id: "drogistEFZ", name: "Drogist*in EFZ" },
            { id: "drogistHF", name: "Drogist*in HF" },
            { id: "fachpersonInAusbildung", name: "Fachperson in Ausbildung" },
            { id: "hebamme", name: "Hebamme" },
            { id: "heilpraktiker", name: "Heilpraktiker*in" },
            { id: "kinderkrankenpfleger", name: "Kinderkrankenpfleger*in" },
            { id: "krankenpflegehelfer", name: "Krankenpflegehelfer*in" },
            {
                id: "krankenpfleger",
                name: "Gesundheits- und Krankenpfleger*in",
            },
            {
                id: "medizinischerPraxisassistent",
                name: "Medizinische Praxisassistent*in",
            },
            { id: "naturheilpraktiker", name: "Naturheilpraktiker*in" },
            { id: "pharmaassistent", name: "Pharmaassistent*in" },
            { id: "psychotherapeut", name: "Psychotherapeut*in" },
            { id: "pta", name: "PTA" },
            { id: "rettungssanitaeter", name: "Rettungssanitäter*in" },
            { id: "tierarzthelfer", name: "Tierarzthelfer*in" },
            { id: "tierarzt", name: "Tierarzt/Tierärztin" },
            { id: "tierheilpraktiker", name: "Tierheilpraktiker*in" },
            { id: "zahnarzt", name: "Zahnarzt/-ärztin" },
        ]}
    />,
];

const CeresUsersList = (props) => (
    <List
        filters={ceresUserFilters}
        exporter={false}
        bulkActionButtons={false}
        sort={{ field: "last_name", order: "ASC" }}
        {...props}
    >
        <Datagrid rowClick="show">
            <TextField source="email" addLabel={true} sortable={false} />
            <TextField
                label="Name"
                source="fullName"
                sortable={true}
                sortBy="last_name"
            />
            <TextField
                label="Verifikationsstatus"
                source="professionVerificationState"
                sortable={false}
            />
            <TextField label="Land" source="countryIsoCode" sortable={false} />
            <TextField
                label="Benutzergruppe"
                source="authorizationGroup"
                sortable={false}
            />
            <TextField
                label="Benutzertyp"
                source="ceresUserType"
                sortable={false}
            />
            <TextField
                label="Registrierungsdatum"
                source="creationDate"
                sortable={true}
                sortByOrder="DESC"
                sortBy="creation"
            />
            <ShowButton />
        </Datagrid>
    </List>
);

const VerifyButton = ({ record }) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const [verifyProfession, { loading }] = useMutation(
        {
            type: "verify",
            resource: "ceres_users",
            payload: {
                record: record,
                id: record?.originId,
            },
        },
        {
            onSuccess: ({ data }) => {
                refresh();
                notify("Beruf verifiziert");
            },
            onFailure: ({ error }) => {
                notify(`Es ist etwas schief gegangen`, { type: "warning" });
            },
        }
    );
    if (record.professionVerificationState === "pending") {
        return (
            <Button
                label="Beruf Verifizieren"
                onClick={verifyProfession}
                disabled={loading}
            />
        );
    }

    return null;
};

const PendingEmail = ({ record }) => {
    if (record.pendingEmail !== null) {
        return (
            <Labeled label="nicht verifizierte Email">
                <TextField source="pendingEmail" />
            </Labeled>
        );
    }

    return null;
};

const CeresUserActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <DeleteButton
            basePath={basePath}
            record={data}
            resource={resource}
            undoable={false}
            confirmTitle="CERES User löschen?"
            confirmContent="Wollen Sie den CERES User wirklich unwiderruflich löschen?"
            label="Löschen"
        />
    </TopToolbar>
);

const CeresUsersShow = (props) => {
    return (
        <Show actions={<CeresUserActions />} title={"Ceres User"} {...props}>
            <TabbedShowLayout>
                <Tab label="Allgemein">
                    <TextField label="Name" source="fullName" />

                    <ReferenceField
                        label="E-Mail"
                        source="updateEmail"
                        reference="admin/update_emails"
                    >
                        <>
                            <ContentCreate />
                            <TextField source="email" />
                        </>
                    </ReferenceField>

                    <TextField
                        label="Verifikationsstatus"
                        source="professionVerificationState"
                    />

                    <DownloadLink basePath={props.basePath} record={props.data}>
                        <>
                            <OpenInNew />
                            <Button label="Berufsnachweis anschauen" />
                        </>
                    </DownloadLink>

                    <VerifyButton
                        basePath={props.basePath}
                        record={props.data}
                    />

                    <TextField label="Beruf" source="profession" />

                    <TextField
                        label="Institution / Praxis"
                        source="contactCompanyName"
                    />

                    <TextField label="Land" source="country" />

                    <TextField label="Benutzertyp" source="ceresUserType" />

                    <TextField
                        label="Benutzergruppe"
                        source="authorizationGroup"
                    />

                    <ReferenceField
                        label="Notiz"
                        source="updateNote"
                        reference="admin/update_notes"
                    >
                        <>
                            <ContentCreate />
                            <TextField source="note" />
                        </>
                    </ReferenceField>
                </Tab>
                <Tab label="Adresse / Kontakt">
                    <TextField label="Strasse" source="contactStreetAddress" />

                    <TextField
                        label="Postleitzahl"
                        source="contactPostalCode"
                    />
                    <TextField label="Ort" source="contactLocality" />

                    <TextField label="Land" source="country" />

                    <TextField label="Telefon" source="contactPhoneNumber" />

                    <TextField
                        label="Firma (Rechnungsanschrift)"
                        source="billingCompanyName"
                        addLabel={true}
                    />

                    <TextField
                        label="Name (Rechnungsanschrift)"
                        source="billingFullName"
                    />
                    <TextField
                        label="Strasse (Rechnungsanschrift)"
                        source="billingStreetAddress"
                    />
                    <TextField
                        label="Postleitzahl (Rechnungsanschrift)"
                        source="billingPostalCode"
                    />
                    <TextField
                        label="Ort (Rechnungsanschrift)"
                        source="billingLocality"
                    />
                    <TextField
                        label="Land (Rechnungsanschrift)"
                        source="billingCountry"
                    />

                    <TextField
                        label="Firma (Lieferanschrift)"
                        source="deliveryCompanyName"
                    />
                    <TextField
                        label="Name (Lieferanschrift)"
                        source="deliveryFullName"
                    />
                    <TextField
                        label="Strasse (Lieferanschrift)"
                        source="deliveryStreetAddress"
                    />
                    <TextField
                        label="Postleitzahl (Lieferanschrift)"
                        source="deliveryPostalCode"
                    />
                    <TextField
                        label="Ort (Lieferanschrift)"
                        source="deliveryLocality"
                    />
                    <TextField
                        label="Land (Lieferanschrift)"
                        source="deliveryCountry"
                    />
                </Tab>
                <Tab label="Sonstiges">
                    <BooleanField
                        label="Email-Adresse verifiziert"
                        source="emailVerified"
                    />
                    <TextField label="Letzter Login" source="lastLoginAt" />
                    <TextField label="Account erstellt" source="creationDate" />
                    <PendingEmail />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

const DownloadLink = ({ record, children }) => {
    const link = useRef();

    const notify = useNotify();

    const [download, { loading }] = useMutation(
        {
            type: "download",
            resource: "ceres_user",
            payload: {
                path: record.professionVerificationDocument?.path,
            },
        },
        {
            onSuccess: async ({ data }) => {
                if (link.current.href) {
                    return;
                }

                link.current.onClick = null;
                link.current.href = data;

                link.current.click();
            },
            onFailure: ({ error }) => {
                notify(`Es ist etwas schief gegangen`, { type: "warning" });
            },
        }
    );

    if (record.professionVerificationDocument !== null) {
        return (
            <a
                role="button"
                ref={link}
                onClick={download}
                target="_blank"
                rel="noopener noreferrer"
            >
                {children}
            </a>
        );
    }

    return null;
};

const UpdateEmail = (props) => {
    const redirect = useRedirect();
    const notify = useNotify();

    const onSuccess = ({ data }) => {
        redirect(
            "show",
            "/admin/ceres_users",
            `/api/admin/ceres_users/${data.originId}`
        );
        notify(
            `Es wurde eine Verifizierungs-E-Mail an ${data.email} geschickt.`
        );
    };

    return (
        <Edit
            title={"E-Mail Adresse bearbeiten"}
            onSuccess={onSuccess}
            mutationMode="pessimistic"
            {...props}
        >
            <SimpleForm toolbar={<UpdateEmailToolbar />}>
                <TextInput source="email" />
            </SimpleForm>
        </Edit>
    );
};

const UpdateNote = (props) => {
    const redirect = useRedirect();
    const notify = useNotify();

    const onSuccess = ({ data }) => {
        redirect(
            "show",
            "/admin/ceres_users",
            `/api/admin/ceres_users/${data.originId}`
        );
        notify(`Der Kommentar wurde gespeichert`);
    };

    return (
        <Edit
            title={"Notiz bearbeiten"}
            onSuccess={onSuccess}
            mutationMode="pessimistic"
            {...props}
        >
            <SimpleForm toolbar={<UpdateNoteToolbar />}>
                <TextInput source="note" multiline fullWidth />
            </SimpleForm>
        </Edit>
    );
};

const UpdateEmailToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

const UpdateNoteToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

const App = () => (
    <HydraAdmin
        disableTelemetry
        theme={defaultTheme}
        layout={Layout}
        entrypoint={ENTRYPOINT}
        authProvider={authProvider}
        dataProvider={dataProvider}
    >
        <ResourceGuesser
            icon={UserIcon}
            options={{ label: "Ceres User" }}
            name="admin/ceres_users"
            list={CeresUsersList}
            show={CeresUsersShow}
            edit={false}
            create={false}
        />
        <ResourceGuesser
            name="admin/update_emails"
            list={false}
            show={false}
            edit={UpdateEmail}
            create={false}
        />

        <ResourceGuesser
            name="admin/update_notes"
            list={false}
            show={false}
            edit={UpdateNote}
            create={false}
        />
        <ResourceGuesser
            icon={UserIcon}
            options={{ label: "Einladung" }}
            name="admin/invite_requests"
            list={InviteRequestList}
            create={InviteRequestCreate}
            edit={false}
            show={false}
        />
    </HydraAdmin>
);

export default App;
