import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    List,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    useMutation,
    useRedirect,
    useNotify,
} from "react-admin";

import { useCallback } from "react";

export const InviteRequestList = (props) => (
    <List exporter={false} bulkActionButtons={false} {...props}>
        <Datagrid>
            <TextField label="E-Mail" source="email" sortable={false} />
            <TextField label="Name" source="fullName" sortable={false} />
            <TextField
                label="Profession"
                source="profession"
                sortable={false}
            />
            <TextField label="Land" source="country" sortable={false} />
            <TextField
                label="Institution / Praxis"
                source="companyName"
                sortable={false}
            />
            <BooleanField
                label="Beruf verifiziert"
                source="professionVerified"
            />
        </Datagrid>
    </List>
);

export const InviteRequestCreate = (props) => {
    const [mutate] = useMutation();
    const notify = useNotify();
    const redirect = useRedirect();
    const save = useCallback(
        async (values) => {
            try {
                await mutate(
                    {
                        type: "create",
                        resource: props.resource,
                        payload: { data: values },
                    },
                    { returnPromise: true }
                );
                notify("Einladung wurde erstellt");
                redirect("list", props.basePath);
            } catch (error) {
                if (!error.body || !error.body[0]) {
                    return null;
                }

                const content = error.body[0];
                const violationKey = Object.keys(content).find((key) =>
                    key.includes("violations")
                );

                if (!violationKey) {
                    return null;
                }

                const base = violationKey.substring(
                    0,
                    violationKey.indexOf("#")
                );
                const violations = content[violationKey].reduce(
                    (violations, violation) =>
                        !violation[`${base}#propertyPath`] ||
                        !violation[`${base}#message`]
                            ? violations
                            : {
                                  ...violations,
                                  [violation[`${base}#propertyPath`][0][
                                      "@value"
                                  ]]: violation[`${base}#message`][0]["@value"],
                              },
                    {}
                );

                if (Object.keys(violations).length === 0) {
                    return null;
                }

                return violations;
            }
        },
        [mutate]
    );

    return (
        <Create {...props}>
            <SimpleForm save={save}>
                <TextInput type="email" label="E-Mail" source="email" />
                <SalutationInput />
                <TextInput type="text" label="Vorname" source="firstName" />
                <TextInput type="text" label="Nachname" source="lastName" />
                <CountryInput />
                <CommunicationLanguageInput />
                <ProfessionInput />
                <BooleanInput
                    label="Beruf verifiziert"
                    source="professionVerified"
                    defaultValue={true}
                />
                <TextInput
                    type="text"
                    label="Strasse und Hausnummer"
                    source="streetAddress"
                />
                <TextInput
                    type="text"
                    label="Postleitzahl"
                    source="postalCode"
                />
                <TextInput type="text" label="Ort" source="locality" />
                <TextInput type="tel" label="Telefon" source="phoneNumber" />
                <TextInput
                    type="text"
                    label="Institution / Praxis"
                    source="companyName"
                />
                <TextInput type="url" label="Website" source="website" />
            </SimpleForm>
        </Create>
    );
};

const SalutationInput = (props) => {
    return (
        <SelectInput
            label="Anrede"
            source="salutation"
            choices={[
                { id: "male", name: "Herr" },
                { id: "female", name: "Frau" },
                { id: "undefined", name: "keine Anrede" },
            ]}
        />
    );
};

const CountryInput = (props) => {
    return (
        <SelectInput
            label="Land"
            source="country"
            choices={[
                { id: "germany", name: "Deutschland" },
                { id: "switzerland", name: "Schweiz" },
                { id: "liechtenstein", name: "Liechtenstein" },
                { id: "austria", name: "Österreich" },
                { id: "france", name: "Frankreich" },
                { id: "italy", name: "Italien" },
                { id: "belgium", name: "Belgien" },
                { id: "czechia", name: "Tschechien" },
                { id: "denmark", name: "Dänemark" },
                { id: "greece", name: "Griechenland" },
                { id: "netherlands", name: "Niederlande" },
                { id: "portugal", name: "Portugal" },
                { id: "spain", name: "Spanien" },
                { id: "sweden", name: "Schweden" },
            ]}
        />
    );
};

const ProfessionInput = (props) => {
    return (
        <SelectInput
            label="Beruf"
            source="profession"
            choices={[
                { id: "no_profession", name: "Keine medizinische Fachperson" },
                { id: "andereFachperson", name: "Andere Fachperson" },
                { id: "apotheker", name: "Apotheker*in" },
                { id: "arzt", name: "Arzt/Ärztin" },
                { id: "drogistEFZ", name: "Drogist*in EFZ" },
                { id: "drogistHF", name: "Drogist*in HF" },
                {
                    id: "fachpersonInAusbildung",
                    name: "Fachperson in Ausbildung",
                },
                { id: "hebamme", name: "Hebamme" },
                { id: "heilpraktiker", name: "Heilpraktiker*in" },
                {
                    id: "medizinischerPraxisassistent",
                    name: "Medizinische Praxisassistent*in",
                },
                { id: "naturheilpraktiker", name: "Naturheilpraktiker*in" },
                { id: "pharmaassistent", name: "Pharmaassistent*in" },
                { id: "psychotherapeut", name: "Psychotherapeut*in" },
                { id: "pta", name: "PTA" },
                { id: "tierarzt", name: "Tierarzt/Tierärztin" },
                { id: "tierheilpraktiker", name: "Tierheilpraktiker*in" },
            ]}
        />
    );
};

const CommunicationLanguageInput = (props) => {
    return (
        <SelectInput
            label="Sprache"
            source="communicationLanguage"
            choices={[
                { id: "german", name: "Deutsch" },
                { id: "french", name: "Französisch" },
            ]}
        />
    );
};
